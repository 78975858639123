<div class="message" [class.me]="chatMessage.userId == currentUserId">
    <div *ngIf="chatMessage.participantName" class="participant" [style.color]="color">
        <p>{{chatMessage.participantName | translate}}</p>
    </div>
    <div *ngIf="!chatMessage.participantName" class="participant" [style.color]="color">
        <p>{{'GUEST' | translate}}</p>
    </div>
    <div class="globe" [style.background]="backgroundColor" [style.border]="borderColor">

      <div *ngIf="chatMessage.chatFile != undefined" class="message-content">

        <div *ngIf="chatMessage.chatFile?.mediaType?.startsWith('image')">
          <img [src]="src" (click)="openMediaModal(chatMessage.chatFile.url!)">
        </div>

        <div *ngIf="chatMessage.chatFile?.mediaType?.startsWith('video')">
          <video [src]="src" controls></video>
        </div>
        <div *ngIf="chatMessage.chatFile?.mediaType?.startsWith('audio')">
          <audio [src]="src" controls></audio>
        </div>

        <img *ngIf="chatMessage.chatFile?.mediaType?.includes('pdf')" src="/assets/images/filetypes/pdf_60x74.png" (click)="downloadFile()">
      </div>

      <!--<img *ngIf="chatMessage.chatFile?.url?.length! > 0" src="/assets/images/filetypes/video-icon_60x54.png" (click)="openVideoUrl()">-->

      <div class="text">
        <p *ngIf="chatMessage.message" [innerHTML]="chatMessage.message | linkify | escapeHtml"></p>
      </div>

      <div class="text" *ngIf="chatMessage.message && chatMessage.translation">
        <p class="translate"><i [innerHTML]="chatMessage.translation | linkify | escapeHtml"></i></p>
      </div>

      <div class="bottom-line">
        <p> {{ chatMessage.date | date:'HH:mm' }}</p>
        <span *ngIf="canDelete" class="chat-icon" (click)="deleteMessage()" id="deleteMessage">
          <i class="material-icons small">delete_outline</i>
        </span>
        <span *ngIf="chatMessage.chatFile?.mediaType?.includes('image')" class="chat-icon" (click)="editImage(chatMessage.chatFile?.fileId!, chatMessage.chatFile?.fileName!)" id="editImage">
          <i class="material-icons small">edit</i>
        </span>
      </div>

    </div>
</div>
