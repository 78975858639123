export const ConnectionData = "ConnectionData";
export const HangUp = "HangUp";
export const StartCall = "StartCall";
export const RoomUpdated = "RoomUpdated";
export const RoomDeleted = "RoomDeleted";
export const ImageReceived = "ImageReceived";
export const CanvasObject = "CanvasObject";
export const LiveDrawingClose = "liveDrawingClose";
export const RemoteRecording = "RemoteRecording";
export const requestHDImage = "RequestHDImage";
export const chatMessage = "ChatMessage";
export const setOpenTokConnectionId = "SetOpenTokConnectionId";
export const audioTranslationReady = "AudioTranslationReady";
export const audioTranscriptionReady = "AudioTranscriptionReady";
export const audioSubtitleReady = "AudioSubtitleReady";
export const setTranslationSubtitles = "SetTranslationSubtitles";
export const setChatTranslation = "SetChatTranslation";
export const setTranslationLanguages = "SetTranslationLanguages";
export const updateTranslationLang = "UpdateTranslationLang";
export const CallNotification = "CallNotification";
export const CallNotificationResponse = "CallNotificationResponse";
export const sendPointer = "SendPointer";
export const showPointer = "ShowPointer";
export const updateMicrophone = "UpdateMicrophone";
export const setMicrophone = "SetMicrophone";
export const updateTranscriptions = "UpdateTranscriptions";
export const updateTranscriptionsLang = "UpdateTranscriptionsLang";
export const setTranscriptions = "SetTranscriptions";
export const setTranscriptionsLang = "SetTranscriptionsLang";
export const setConnectionId = "SetConnectionId";
export const addParticipant = "AddParticipant";
export const disconnectParticipant = "DisconnectParticipant";
export const startTranscription = "StartTranscription";
export const startTranslation = "StartTranslation";
export const stopTranslation = "StopTranslation";
export const stopAudioConnector = "StopAudioConnector";
export const deleteChatMessage = "DeleteChatMessage";
