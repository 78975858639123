import { Injectable } from "@angular/core";
import * as RecordRTC from "recordrtc"
import { Observable, Subject } from "rxjs";

/*
https://recordrtc.org/
*/

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

@Injectable({
    providedIn: 'root'
  })

  export class RecordMediaService {
    private stream:any;
    private recorder:any;
    private _recorded = new Subject<RecordedAudioOutput>();
    private _recordingFailed = new Subject<string>();

    getRecordedBlob(): Observable<RecordedAudioOutput> {
      return this._recorded.asObservable();
    }
  
    recordingFailed(): Observable<string> {
      return this._recordingFailed.asObservable();
    }

    startRecordingAudio() {
        if (this.recorder) {
          return;
        }
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(s => {
            this.stream = s;
            this.recordAudio();
          })
          .catch(error => {
            this._recordingFailed.next(error);
          });
      }

    private recordAudio() {
      this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
        type: "audio",
        mimeType: "audio/webm",
        numberOfAudioChannels: 1
      });
  
      this.recorder.record();
    }


    stopRecordingAudio() {
      if (this.recorder) {
        this.recorder.stop(
          (blob: any) => {
              const mp3Name = encodeURIComponent(
                "audio_" + new Date().getTime() + ".wav"
              );
              this.stopMediaAudio();
              this._recorded.next({ blob: blob, title: mp3Name });
            //}
          },
          () => {
            this.stopMediaAudio();
            this._recordingFailed.next("failed");
          }
        );
      }
    }

    private stopMediaAudio() {
      if (this.recorder) {
        this.recorder = null;
        if (this.stream) {
          this.stream.getAudioTracks().forEach((track: { stop: () => any; }) => track.stop());
          this.stream = null;
        }
      }
    }
  }