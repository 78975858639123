<div style="background-color: #161A29" *ngIf="!error">

  <div class="video-container">
    <video #video id="video" autoplay muted></video>
  </div>


  <div *ngIf="!error" class="buttons-area">
    <div class="round-button close-button" (click)="close()">
      <img src="/assets/images/buttons/close.svg" alt="Icono">
    </div>

    <div class="round-button capture-tool-button material-icons md-36 cursorPointer" (click)="capture()"> photo_camera </div>

    <div *ngIf="!error && hasMultipleCameras" class="round-button capture-tool-button material-icons md-36 md-dark cursorDefault" (click)="switchCamera()"> cameraswitch </div>
    <div *ngIf="error || !hasMultipleCameras" class="round-button capture-tool-button material-icons md-36 md-dark md-inactive cursorDefault"> cameraswitch </div>

  </div>
</div>


<div *ngIf="error">
  <!--<div class="video-container">
    <p class="text-danger mt-4">{{ error }}</p>
  </div>

  <div class="buttons-area">
    <div class="round-button close-button" (click)="exit()">
      <img src="/assets/images/buttons/close.svg" alt="Icono">
    </div>
  </div>-->

  <h1 mat-dialog-title>Error</h1>
  <div mat-dialog-content>
    <p>{{ error }}</p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="close()">{{ 'MODAL.CLOSE' | translate}}</button>
  </div>


</div>


