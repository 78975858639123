<div class="directCallChat panel" [class.stickyMode]="stickyMode">

  <div *ngIf="!stickyMode" class="close" (click)="closeChat()">
    <label class="close-icon">
      <i class="material-icons">close</i>
    </label>
  </div>

  <div #messagesContainer class="messagesContainer" [class.scroll-animated]="!firstLoad">
    <div *ngFor="let messageGroup of group">
      <div class="dateDivider">
        <div class="date">{{ messageGroup[0].date | date:'mediumDate' }}</div>
        <div class="line"></div>
      </div>
      <div *ngFor="let message of messageGroup">
        <directCall-chat-message [chatMessage]="message"></directCall-chat-message>
      </div>
    </div>
  </div>

  <div class="newMessage">

    <label class="file-input-icon icon1" for="fileInput">
      <i class="material-icons">attach_file</i>
    </label>


    <label class="file-input-icon icon2" (click)="takePhoto()">
      <i class="material-icons">add_a_photo</i>
    </label>
 
    <label class="file-input-icon icon3" (click)="recordVoice()">
      <i *ngIf="!isRecordingVoice"class="material-icons">mic</i>
      <i *ngIf="isRecordingVoice" class="material-icons blinking">stop</i>
    </label>

    <input #fileInput id="fileInput" type="file" (change)="onFileSelected($event)" accept="image/*,video/*,application/pdf,audio/wav">
    <textarea #textarea class="inputText" [(ngModel)]="newMessage.message" id="chatInput" (input)="adjustTextareaHeight()" (keyup.enter)="addNewMessage()"></textarea>
    <img id="sendChat" src="/assets/images/chat/send.svg" class="sendButton" (click)="addNewMessage()">

  </div>
    <div *ngIf="newMessage.chatFile?.file" class="attachment-info">
      <p class="attachment-name">{{ newMessage.chatFile?.file?.name }}</p>
      <i (click)="clearAttachment()" class="material-icons">delete_outline</i>
    </div>
    <div *ngIf="attachment" class="attachment-info">
      <p class="attachment-name">{{ attachment.size }}</p>
      <i (click)="clearAttachment()" class="material-icons">delete_outline</i>
    </div>

  </div>
