import { Injectable } from '@angular/core';
import { AuthTokenResponse } from '../interfaces/Responses/AuthTokenResponse';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { EncryptStorage } from 'encrypt-storage';
import { environment } from 'src/environment/environment';
import { ApiUrls } from '../constants/constants';

@Injectable({
  providedIn: 'root',
})
export class SecurityService implements HttpInterceptor {
  token: string | null = null;
  tokenType: string | null = null;
  encryptStorage = new EncryptStorage('secret-key-value', {
    storageType: 'localStorage',
  });
  constructor(private configurationService: ConfigService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;


    let headers = new HttpHeaders({ 'language': this.configurationService.language });

    if (this.token && this.tokenType) {
      headers = headers.append('authorization', `${this.tokenType} ${this.token}`)
    }

    if (!request.url.includes(ApiUrls.sendChatMessage)) {
      headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    }

    request = req.clone({ headers });

    console.log('content-type', request.headers);
    return next.handle(request);
  }

  public addSecurityToken(authTokenResponse: AuthTokenResponse) {
    this.token = authTokenResponse.token;
    this.tokenType = authTokenResponse.authType;
  }

  public getUrlToken(): string | null {
    let encrypted = sessionStorage.getItem(environment.tokenName);
    let token = this.encryptStorage.decryptValue(encrypted!);
    return token;
  }

  public saveUrlToken(token: string) {
    let encrypted = this.encryptStorage.encryptValue(token);
    sessionStorage.setItem(environment.tokenName, encrypted);
  }
}
