import { EventEmitter, Injectable, QueryList } from '@angular/core';
import { UserDataService } from './userData.service';
import { ApiService } from './communications/api.service';
import { RoomParticipants } from '../interfaces/RoomInfo';
import { SignalRService } from './communications/signalr.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { catchError, from, lastValueFrom, switchMap, throwError } from 'rxjs';
import { PublisherComponent } from '../components/publisher/publisher.component';
import * as constants from 'src/app/constants/constants';
import { StartAudioConnectorResponse } from '../interfaces/Responses/AudioConnectorResponse';

@Injectable({
  providedIn: 'root'
})

export class AudioConnectorService {

  translateSubtitlesOn = new BehaviorSubject<boolean>(false);
  translateSubtitleEnabled = new BehaviorSubject<boolean>(false);
  translationVoiceEnabled = new BehaviorSubject<boolean>(false);

  translateSubtitlesOn$ = this.translateSubtitlesOn.asObservable();
  translateSubtitleEnabled$ = this.translateSubtitleEnabled.asObservable();
  translationVoiceEnabled$ = this.translationVoiceEnabled.asObservable();

  onStopAudioConnector = new EventEmitter<string>();

  constructor(private userDataService: UserDataService, private apiService: ApiService, private signalRService: SignalRService) {
   
  }

  start(participant: RoomParticipants) {
    var result = this.signalRService.startTranscription(this.userDataService.otSession!, this.userDataService.CallId!, this.userDataService.GetTranslatedStreamId(participant.connectionId).streamId,
      participant.connectionId, this.userDataService.roomInfo.transcriptionsLanguage!);

    result.then(res => {
      if (this.userDataService.roomInfo.roomParticipants.some(p => p.translateVoiceOn)) this.translateSubtitleEnabled.next(true);
      console.log("START audioConnectorId", result);
    });
  }

  startTranslation(participant: RoomParticipants) {
    var result = this.signalRService.startTranslation(this.userDataService.otSession!, this.userDataService.CallId!, participant.translationLanguage,
      this.userDataService.GetTranslatedStreamId(participant.connectionId).streamId, participant.connectionId, participant.originLanguage);

    result.then(res => {
      if (this.userDataService.roomInfo.roomParticipants.some(p => p.translateVoiceOn)) this.translateSubtitleEnabled.next(true);
      console.log("START audioConnectorId", result);
    });
  }

  stop(participant: RoomParticipants) {
    var result = this.signalRService.stopAudioConnector(this.userDataService.otSession!, participant.audioConnectorId!, participant.connectionId);
    result.then(res => {
      participant.audioConnectorId = "";
      console.log("stop", res, this.userDataService.roomInfo?.roomParticipants);

      if (this.userDataService.roomInfo?.roomParticipants.some(p => p.audioConnectorId != undefined && p.audioConnectorId != '')) {
        console.log("no stop subtitles");
        return;
      }

      if (!participant.microphone) this.translationVoiceEnabled.next(false);

      this.translateSubtitleEnabled.next(false);
      console.log("stop subtitles", this.translateSubtitleEnabled.value);

      if (this.translateSubtitlesOn.value) this.translateSubtitles();
    })
    .catch(error => {
      console.log("Error on  : stopTranslation => " + error);
    });
  }

  stopTranslations(participant: RoomParticipants) {
    var result = this.signalRService.stopTranslations(this.userDataService.otSession!, this.userDataService.CallId!, this.userDataService.GetTranslatedStreamId(participant.connectionId).streamId, participant.connectionId);
    result.then(res => {
      if (!this.userDataService.roomInfo.transcriptionsOn) participant.audioConnectorId = "";

      participant.translateVoiceOn = false;
      this.onStopAudioConnector.emit(participant.connectionId);

      console.log("stop", res, this.userDataService.roomInfo?.roomParticipants);

      if (this.userDataService.roomInfo?.roomParticipants.some(p => p.audioConnectorId != undefined && p.audioConnectorId != '')) {
        console.log("no stop subtitles");
        return;
      }

      if (!participant.microphone) this.translationVoiceEnabled.next(false);

      this.translateSubtitleEnabled.next(false);
      console.log("stop subtitles", this.translateSubtitleEnabled.value);

      if (this.translateSubtitlesOn.value) this.translateSubtitles();

    })
      .catch(error => {
        console.log("Error on  : stopTranslation => " + error);
      });
  }

  translateSubtitles() {
    this.translateSubtitlesOn.next(!this.translateSubtitlesOn.value);
    this.userDataService.roomInfo!.roomParticipants.forEach(participant => participant.translateSubtitleOn = this.translateSubtitlesOn.value);
    this.signalRService.setTranslateSubtitles(this.userDataService.RoomId!, this.translateSubtitlesOn.value);
    console.log('translation subtitles on');
  }

  setTranslateSubtitlesOn(value: boolean) {
    this.translateSubtitlesOn.next(value);
  }

  setTranslationVoiceEnabled(value: boolean) {
    this.translationVoiceEnabled.next(value);
  }

  startAudioTranslate(publishers: QueryList<PublisherComponent>, data: StartAudioConnectorResponse) {
    console.log(data.userId, this.userDataService.Id);

    var participant = this.userDataService.roomInfo?.roomParticipants.find(p => p.id == data.userId);
    if (participant != undefined) {
      console.log("startAudioTranslate - set audioConnectId - translateActive", participant.name, data.translateActive);
      participant.audioConnectorId = data.audioConnectorId;
      participant.translateVoiceOn = data.translateActive;
    }
    else console.log("startAudioTranslate. UserName not found. Not audioconnectorId set!", data.userId);

    if (data.userId != this.userDataService.Id) return;
    if (!participant?.translateVoiceOn || participant.translateVoiceOn == undefined) return;

    console.log("startAudioTranslate", data.userId, participant?.translateVoiceOn);

    const targetPublisher = publishers.find(publisher => publisher.publisherType == constants.PublisherType.AudioTranslate);
    targetPublisher?.createAudioTranslatePublisher(data);
    targetPublisher?.publisher.on("streamCreated", event => {
      console.log("STREAM CREATED PUBLIHSER AUDIO CONNECTOR", event.stream.name);
    });

    targetPublisher?.publisher.on("streamDestroyed", event => {
      console.log("STREAM DESTROYED PUBLIHSER AUDIO CONNECTOR", event.stream.name);
    });
  }

  stopAudioTranslate(publishers: QueryList<PublisherComponent>) {
    console.log("stop audio connector from call component!");
    const targetPublisher = publishers.find(publisher => publisher.publisherType == constants.PublisherType.AudioTranslate);
    if (targetPublisher?.publisher == null) {
      console.log("publisher null");
      return;
    }
    targetPublisher?.publisher.destroy();
  }

  publishTranslatedAudio(publishers: QueryList<PublisherComponent>, filePath: string) {
    const audioContext = new (window.AudioContext)(); // || window.webkitAudioContext)();

    const targetPublisher = publishers.find(publisher => publisher.publisherType == constants.PublisherType.AudioTranslate);
    if (targetPublisher?.publisher == null) {
      console.log("publisher null");
      return;
    }

    console.log(filePath);

    this.getAudioBuffer(filePath, audioContext).then(myArrayBuffer => {
      if (myArrayBuffer == null) {
        console.log("BUFFER NULL");
        return;
      }
      const source = audioContext.createBufferSource();
      source.buffer = myArrayBuffer; // set the buffer in the AudioBufferSourceNode

      const destination = audioContext.createMediaStreamDestination();

      source.connect(destination); // connect the AudioBufferSourceNode to the destination so we can hear the sound
      source.start(); // start the source playing

      //cambia audio, solo se oye el mp3

      targetPublisher?.publisher.setAudioSource(destination.stream.getAudioTracks()[0]);
      console.log("set new audio source");

    }).catch(error => {
      console.error('Error:', error);
    });
  }

  getAudioBuffer(url: string, audioContext: AudioContext): Promise<AudioBuffer> {
    return lastValueFrom(this.apiService.getAudioFile(url)
      .pipe(
        switchMap(audioData => from(audioContext.decodeAudioData(audioData))),
        catchError(error => {
          console.error('Error fetching or decoding audio data:', error);
          return throwError(error);
        })
      ));
  }

}


