import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FileHelperService } from 'src/app/services/file-helper.service';


@Component({
  selector: 'app-cameraComponent',
  templateUrl: './cameraComponent.html',
  styleUrls: ['./cameraComponent.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, TranslateModule]
})

export class CameraComponent implements AfterViewInit {

  //videoWidth = 640;
  //videoHeigth = 480;

  @ViewChild("video") public video!: ElementRef;

  error: any;
  hasMultipleCameras: boolean = true;
  facingMode: 'user' | 'environment' = 'user';
  stream: MediaStream | null = null;

  constructor(public dialogRef: MatDialogRef<CameraComponent>, @Inject(MAT_DIALOG_DATA) public data: File, private fileService: FileHelperService) {
    console.log("Open camera");
    this.error = false;
  }

  async ngAfterViewInit() {
    this.checkCameras();
  }

  checkCameras() {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoDevices = devices.filter(device => device.kind === 'videoinput');

      if (videoDevices.length === 0) {
        this.error = 'No se encontraron cámaras en este dispositivo.';
      } else if (videoDevices.length === 1) {
        this.hasMultipleCameras = false;
        this.startCamera(); 
      } else {
        this.hasMultipleCameras = true;
        this.startCamera(); 
      }
    }).catch(error => {
      this.error = 'Error al acceder a las cámaras: ' + error.message;
    });
  }

  switchCamera() {
    this.facingMode = this.facingMode == 'user' ? 'environment' : 'user';
    this.startCamera();
  }

  startCamera() {
    if (this.stream) {
      // Detenemos las pistas actuales antes de iniciar una nueva
      this.stream.getTracks().forEach(track => track.stop());
    }

    let that = this;
    navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: this.facingMode,
        width: { ideal: 1280 }
      }
    }).then(function (mediaStream) {
      
      if (mediaStream) {
        that.stream = mediaStream;
        that.video.nativeElement.srcObject = mediaStream;
        that.video.nativeElement.play();
        that.error = null;
      } else {
        that.error = "You have no output video device";
      }
    }).catch(function (err) {
      that.error = err;
    });
  }


  capture() {
   const canvas:HTMLCanvasElement = this.drawImageToCanvas(this.video.nativeElement);

    canvas.toBlob((blob: Blob | null) => {
      console.log(blob);
      if (blob != null) {
        this.data = this.fileService.blobToFile(blob, 'captured.png');
        this.dialogRef.close(this.data);
      }
    })
    canvas.remove();
  }

  drawImageToCanvas(image: any) : HTMLCanvasElement {
    console.log("video resolution:", this.video.nativeElement.videoWidth, this.video.nativeElement.videoHeight);
    const newCanvas = document.createElement("canvas");
    newCanvas.setAttribute("id", "canvasTemp");
    newCanvas.width = this.video.nativeElement.videoWidth;
    newCanvas.height  = this.video.nativeElement.videoHeight;
    newCanvas.getContext("2d")!.drawImage(image, 0, 0, this.video.nativeElement.videoWidth, this.video.nativeElement.videoHeight);
    console.log(newCanvas);
    return newCanvas;
  }

  close() {
    this.dialogRef.close(null);
  }

  ngOnDestroy() {
    // Detenemos las pistas de video cuando el componente se destruye
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  }
}

