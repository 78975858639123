import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MediaType } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})

export class FileHelperService {
  private static readonly imageFilesExtensions: string[] = ["jpg", "png", "jpeg", "gif"];
  private static readonly documentFilesExtensions: string[] = ["pdf"];
  private static readonly videoFilesExtensions: string[] = ["mp4", "mov", "mpeg", "avi", "3gpp"];
  private static readonly audioFilesExtensions: string[] = ["wav"];



  constructor(private sanitizer: DomSanitizer) {
  }

  isUrlVideo(fileUrl: string | undefined): boolean {
    if (fileUrl?.startsWith('video')) {
      console.log('URL file is type video', fileUrl);
      return true;
    }
    return false;
  }

  isUrlImage(fileUrl: string | undefined): boolean {
    if (fileUrl?.startsWith('image')) {
      console.log('URL file is type image', fileUrl);
      return true;
    }
    return false;
  }

  isUrlAudio(fileUrl: string | undefined): boolean {
    if (fileUrl?.startsWith('audio')) {
      console.log('URL file is type image', fileUrl);
      return true;
    }
    return false;
  }

  isFileVideo(file: File): boolean {
    if (file.type.startsWith('video/')) {
      console.log('File type is video ', file.name);
      return true;
    }
    return false;
  }

  isFileImage(file: File): boolean {
    if (file.type.startsWith('image/')) {
      console.log('File type is image ', file.name);
      return true;
    }
    return false;
  }

  isFileAudio(file: File): boolean {
    if (file.type.startsWith('audio/')) {
      console.log('File type is audio ', file.name);
      return true;
    }
    return false;
  }

  convertFileToUrl(file: File): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  isUrl(fileOrUrl: any): boolean {
    return typeof fileOrUrl === 'string';
  }

  getFileType(fileName: string): MediaType {
    // Get the file extension in lowercase for case-insensitive comparison
    const fileExtension = this.getFileExtension(fileName).toLowerCase();

    // Use a switch statement with type guards for more control and type safety
    switch (true) {
      case FileHelperService.imageFilesExtensions.includes(fileExtension):
        return MediaType.Image;
      case FileHelperService.documentFilesExtensions.includes(fileExtension):
        return MediaType.Document;
      case FileHelperService.videoFilesExtensions.includes(fileExtension):
        return MediaType.Video;
      case FileHelperService.audioFilesExtensions.includes(fileExtension):
        return MediaType.Audio;
      default:
        return MediaType.Unknown;
    }
  }

  getFileExtension(filename: string): string {
    // Use a regular expression to match the file extension
    const match = filename.match(/\.(.+)$/);
    // If a match is found, return the captured extension
    if (match) {
      return match[1];
    }
    // If no match is found, return an empty string
    return '';
  }

  blobToFile = (blob: Blob, fileName: string): File => {
    const b: any = blob;

    b.lastModifiedDate = new Date();
    b.name = fileName;
    b.filename = fileName;

    return new File([b], 'captured.png', { type: 'image/png' })
  }

  fileToBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
      reader.onerror = error => reject(error);
    })
  }

  urltoFile(url: string, filename: string, mimeType: string) {
    if (url.startsWith('data:')) {
      var arr = url.split(','),
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var file = new File([u8arr], filename, { type: mimeType });
      return Promise.resolve(file);
    }
    return fetch(url)
      .then(res => res.arrayBuffer())
      .then(buf => new File([buf], filename, { type: mimeType }));
  }


}