import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { chatMessage } from 'src/app/models/chatMessage';
import { MatDialog } from '@angular/material/dialog';
import { MediaPreviewComponent } from './media-preview/media-preview.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '../../../pipes/pipes.module';
import { FileHelperService } from 'src/app/services/file-helper.service';
import { UserDataService } from 'src/app/services/userData.service';
import { TranslateModule } from '@ngx-translate/core';
import { ChatService } from '../../../services/chat.service';
import { ApiService } from 'src/app/services/communications/api.service';
import { ImageEditorResultData } from 'src/app/interfaces/imageeditor-resultdata';
import * as constants from 'src/app/constants/constants';
import { ImageEditorComponent } from '../../image-editor/image-editor.component';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'directCall-chat-message',
  standalone: true,
  imports: [CommonModule, MatDialogModule, PipesModule, TranslateModule],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})

export class MessageComponent implements OnInit {
  @Input() chatMessage: chatMessage = new chatMessage(-1, '');
  currentUserId: number;
  roomId: number;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  src?: SafeUrl | File;
  canDelete: boolean = false;

  constructor(public dialog: MatDialog, public fileHelperService: FileHelperService, public userDataService: UserDataService, private chatService: ChatService, private apiServices: ApiService) {
    this.currentUserId = userDataService.Id!;
    this.roomId = userDataService.RoomId!;
  }

  ngOnInit() {
    this.color = this.chatMessage.color;
    this.backgroundColor = `rgba(${this.chatService.getBackgroundColor(this.chatMessage.color!)}, 0.1)`;
    this.borderColor = `1px solid ${this.chatMessage.color}`;
    this.src = this.chatMessage.chatFile?.url ?? "";

    console.log("SRC: ", this.src);

    console.log('color', this.chatMessage.color);
    this.canDelete = this.currentUserId == this.chatMessage.userId;
  }

  openMediaModal(url: string): void {
    this.dialog.open(MediaPreviewComponent, {
      height: 'auto', 
      maxHeight: '90vh',
      data: url
    });
  }

  downloadFile() {
    //var isUrl = this.fileHelperService.isUrl(this.chatMessage.chatFile?.file);
    //var url;

    //if (isUrl) {
    //  url = this.chatMessage.chatFile?.url;
    //} else {
    //  url = window.URL.createObjectURL(this.chatMessage.chatFile!.file!);      
    //}

    window.open(this.chatMessage.chatFile?.url, '_blank');
  }

  openVideoUrl() {
    const url = this.chatMessage.chatFile?.url;
    window.open(url, '_blank');
  }

  deleteMessage() {
    console.log("delete messageid:", this.chatMessage.messageId);
    this.apiServices.deleteMessage(this.chatMessage.messageId!, this.currentUserId, this.roomId).subscribe({
      next: (data) => {
        console.log("delete message sent ok");
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  editImage(id: number, fileName: string) {
    console.log("File to edit", id);
    this.apiServices.getBase64Image(id, this.userDataService?.RoomId!, this.userDataService.Id!).subscribe({
      next: data => {
        console.log("Receved data", data);
        let dialogEditor = this.dialog.open(ImageEditorComponent, {
          panelClass: 'edit-image-dialog',
          data: { img: data.base64Image, mode: constants.ImageEditorModes.Edit, isExpert: this.userDataService.isExpert, isHdImage: false },
        });
        dialogEditor.afterClosed().subscribe((result: ImageEditorResultData) => {
          this.processEditedImage(result, "edited_" + fileName, data.mimeType);
        });
      },
      error: err => {
        console.error("Error image to edit" + JSON.stringify(err));

      }
    });
  }

  processEditedImage(result: ImageEditorResultData, filename: string, mime: string) {
    console.log("* Process image edit response");
    console.log("Close by :" + constants.ImageEditorActions[result.action]);
    switch (result.action) {
      case constants.ImageEditorActions.saveAndClose: {
        let file = this.fileHelperService.urltoFile(result.originalImageBase64, filename, mime).then((result: File) => {
          this.chatService.imageEdited(result);
        });
        break;
      }
      default: {
        console.log('The dialog was closed, nothing to do');
        break;
      }
    }
  }
}
