<div *ngIf="url" class="dialog-content">
  <div mat-dialog-content>


    <img class="media-preview" [src]="url">
    <!-- lo quito porque el visor de vídeo ya tiene función de ampliar, si encontrais algún navegador que no tiene está hecho. -->
    <!-- *ngIf="!fileHelperService.isURLVideo(file)" -->
    <!-- <video class="media-preview" *ngIf="fileHelperService.isURLVideo(file)" [src]="file" controls autoplay></video> -->
    <!--<div *ngIf="!fileHelperService.isUrl(url)" class="file-area">
        <img class="media-preview" [src]="fileHelperService.convertFileToUrl(url)" >-->
    <!-- *ngIf="fileHelperService.isFileImage(file)"  -->
    <!-- <video class="media-preview" *ngIf="fileHelperService.isFileVideo(file)" [src]="fileHelperService.convertFileToUrl(file)"controls autoplay></video> -->
    <!--</div>-->
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button (click)="close()">{{ 'MODAL.CLOSE' | translate}}</button>
  </div>
</div>
