import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as constants from 'src/app/constants/constants';
import { ImageEditorDialogData } from 'src/app/interfaces/imageeditor-dialogdata';
import { ImageEditorResultData } from 'src/app/interfaces/imageeditor-resultdata';
import { CommonModule } from '@angular/common';
import { SignalRService } from 'src/app/services/communications/signalr.service';
import { FabricService } from 'src/app/services/fabric.service';
import { ApiService } from 'src/app/services/communications/api.service';
import { UserDataService } from 'src/app/services/userData.service';
import { ModifiedObjectsEmitterData } from 'src/app/interfaces/SignalRDrawObject';
import * as canvasConstants from 'src/app/constants/canvas-constants';
import { AlignButtonsService } from '../../services/buttons.service';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.css'],
  standalone: true,
  imports: [CommonModule]
})

export class ImageEditorComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('editImgContainer') container!: ElementRef;
  @ViewChildren('editImgButton') buttonElements!: QueryList<ElementRef>;

  readonly EDITOR_CONSTANTS = constants.ImageEditorModes;
  startUpColor = canvasConstants.defaultEditorColor;
  editorMode: constants.ImageEditorModes;
  imageLink: string = "data:image/png;base64, ";
  fabricService!: FabricService;
  tempHidden: boolean = true;
  isLiveStrokeDraw: boolean = false;
  showSaveButton:boolean = true;

  constructor(public dialogRef: MatDialogRef<ImageEditorComponent>, @Inject(MAT_DIALOG_DATA) public data: ImageEditorDialogData, private signalRService: SignalRService,
    private apiService: ApiService, private userDataService: UserDataService, private buttonsService: AlignButtonsService) {
    if (data.img.includes('data'))
      this.imageLink = data.img;
    else
      this.imageLink = this.imageLink + data.img;
    
    this.dialogRef.disableClose = true;
    this.showSaveButton=data.showSaveButton;
    console.log("editor mode received:" + constants.ImageEditorModes[data.mode]);
    this.editorMode = data.mode;
    console.log("create fabric");
    this.fabricService = new FabricService(this.imageLink, this.data.isExpert, this.data.isLiveMode, this.userDataService.Id!);
  }

  ngOnInit() {
    console.log("loadCanvas canvas");
    this.fabricService.loadCanvas(this.canvasRef);
    console.log("loadCanvas image on init");
    this.fabricService.loadImg();
  }

  ngAfterViewInit() {
    console.log("loadCanvas image, after view init");

    this.eventsSubscribe();
    this.freeDrawing(true);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.fabricService.adjustCanvasSize();
    this.buttonsService.adjustButtonDisplay(this.container, this.buttonElements);
  }

  saveAndClose(sendImage: boolean) {
    console.log("* Save and close image-editor component");
    var base64ImageResult = "";
    if (this.fabricService.hasObjects()) {
      base64ImageResult = this.fabricService.getImage();
    }
    else
      base64ImageResult = this.data.img;

    let result: ImageEditorResultData = {
      action: constants.ImageEditorActions.saveAndClose,
      originalImageBase64: base64ImageResult,
      send: sendImage,
      isLiveDrawing: (this.editorMode == constants.ImageEditorModes.LiveDraw),
      isHdImg: this.data.isHdImage
    };

    this.dialogRef.close(result);
  }

  close() {
    console.log("Close clicked editor mode:" + constants.ImageEditorModes[this.editorMode]);
    let closeAction: constants.ImageEditorActions = this.editorMode === constants.ImageEditorModes.LiveDraw ? constants.ImageEditorActions.RemoteClose : constants.ImageEditorActions.Close;
    this.dialogRef.close({ action: closeAction, originalImageBase64: this.data.img, send: false, isLiveDrawing: (this.editorMode == constants.ImageEditorModes.LiveDraw), isHdImg: this.data.isHdImage });
  }

  drawSquare() {
    this.freeDrawing(false);
    this.fabricService.addRectangle();
  }

  freeDrawing(value: boolean | null) {
    console.log("Draw stroke :" + this.isLiveStrokeDraw);
    if (value == null)
      this.fabricService.freeDrawing(!this.fabricService.isFreeDrawing());
    else
      this.fabricService.freeDrawing(value);
    this.isLiveStrokeDraw = this.fabricService.isFreeDrawing();
  }

  eraseAll() {
    this.fabricService.eraseAll();
  }

  updateColor(event: Event) {
    this.fabricService.changeColor((event.target as HTMLTextAreaElement).value);
  }

  writeText() {
    this.freeDrawing(false);
    this.fabricService.addText();
  }

  startLiveDraw() {
    switch (this.editorMode) {
      case constants.ImageEditorModes.Edit:
        this.editorMode = constants.ImageEditorModes.LiveDraw;
        this.apiService.startLiveDrawing(this.userDataService.CallId!, this.data.img, this.userDataService.Id!, this.signalRService.getConnectionId()!, this.userDataService.RoomId!).subscribe((response) => {
          console.log(response);
        })
        break;
      case constants.ImageEditorModes.LiveDraw:
        this.editorMode = constants.ImageEditorModes.Edit;
        this.fabricService.stopFreeDrawing();
        this.signalRService.liveDrawingClose(this.userDataService.RoomId!, this.userDataService.Id!);
        break;
    }
    this.isLiveStrokeDraw = this.fabricService.isFreeDrawing();
    console.log("Draw stroke :" + this.isLiveStrokeDraw);
    this.fabricService.clear();
  }

  eventsSubscribe() {
    this.fabricService.OnObjectModified.subscribe(response => {
      if (this.editorMode === this.EDITOR_CONSTANTS.LiveDraw || !this.data.isExpert) {
        this.signalRService.SendCanvasObject(this.userDataService.Id!, this.userDataService.RoomId!, response);
        console.log("canvas onject sent ");
      }
    });

    this.signalRService.onCanvasObjectReceived.subscribe((data: ModifiedObjectsEmitterData) => {
      console.log("liveObject received");
      this.fabricService!.liveObject(data);
    });

    this.signalRService.OnLiveDrawingClose.subscribe(() => {
      this.closeLiveDrawing();
    });

  }

  closeLiveDrawing() {
    console.log("Close Live Drawing");
    const result: ImageEditorResultData = {
      action: constants.ImageEditorActions.RemoteClose,
      originalImageBase64: this.data.img,
      send: false,
      isLiveDrawing: (this.editorMode == constants.ImageEditorModes.LiveDraw),
      isHdImg: this.data.isHdImage
    };

    this.dialogRef.close(result);
  }

  ngOnDestroy(): void {
    this.fabricService.destroy();
  }
}
