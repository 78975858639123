import { MediaType } from "../constants/constants";

export class chatFile {
  constructor(
    public fileId: number,
    public fileName: string,
    public mediaType: string,
    public url: string,
    public file: File | null
  ) { }
}
